<script lang="ts" setup> 

    const props = defineProps({ blok: Object }) 

    const width = computed(() => {
        return props.blok?.container_background_image?.filename.split('/')[5].split('x')[0]
    })

    const height = computed(() => {
        return props.blok?.container_background_image?.filename.split('/')[5].split('x')[1]
    })

</script>

<template>
    <section 
        v-if="blok" 
        v-editable="blok" 
        class="relative"
        :class="[
            blok?.container_background_colour,
            blok?.container_margin_top_mobile,
            blok?.container_margin_top_tablet,
            blok?.container_margin_top_desktop,
            blok?.container_margin_bottom_mobile,
            blok?.container_margin_bottom_tablet,
            blok?.container_margin_bottom_desktop,
            blok?.container_padding_top_mobile,
            blok?.container_padding_top_tablet,
            blok?.container_padding_top_desktop,
            blok?.container_padding_bottom_mobile,
            blok?.container_padding_bottom_tablet,
            blok?.container_padding_bottom_desktop
        ]"
    >
        <div 
            class="container relative z-20"
            :class="[
                blok?.container_width_mobile,
                blok?.container_width_tablet,
                blok?.container_width_desktop,
                blok?.container_height || 'h-auto'
            ]"
        >
            <StoryblokComponent v-for="block in blok.blocks" :key="block._uid" :blok="block" />
        </div>
        <template v-if="blok?.container_background_image && blok?.container_background_image?.filename && blok?.container_background_image_preload">
            <NuxtImg
                v-if="blok?.container_background_image && blok?.container_background_image?.filename"
                provider="storyblok"
                :width="width"
                :height="height" 
                :sizes="`xs:100vw sm:100vw md:100vw narrow:${width} lg:${width} xl:${width} 1xl:${width} normal:${width} 2xl:${width} 3xl:${width} wide:${width}`"     
                quality="75"
                format="webp"
                fit="in"
                :src="blok?.container_background_image?.filename"
                :alt="blok?.container_background_image?.alt || blok?.container_background_image?.title || '#'" 
                :fetchpriority="blok?.container_background_image_fetchPriority || 'auto'"
                :decoding="blok?.container_background_image_decoding || 'async'"
                :loading="blok?.container_background_image_loading || 'lazy'"
                class="absolute top-0 left-0 w-full h-full object-cover z-10"
                :class="[
                    blok?.container_background_image_position || 'object-center'
                ]"
                preload
            />
        </template>
        <template v-else>
            <NuxtImg
                v-if="blok?.container_background_image && blok?.container_background_image?.filename"
                provider="storyblok"
                :width="width"
                :height="height" 
                :sizes="`xs:100vw sm:${width} md:${width} narrow:${width} lg:${width} xl:${width} 1xl:${width} normal:${width} 2xl:${width} 3xl:${width} wide:${width}`"     
                quality="75"
                format="webp"
                fit="in"
                :placeholder="[width, height, 10, 5]"
                :src="blok?.container_background_image?.filename"
                :alt="blok?.container_background_image?.alt || blok?.container_background_image?.title || '#'" 
                :fetchpriority="blok?.container_background_image_fetchPriority || 'auto'"
                :decoding="blok?.container_background_image_decoding || 'async'"
                :loading="blok?.container_background_image_loading || 'lazy'"
                class="absolute top-0 left-0 w-full h-full object-cover z-10"
                :class="[
                    blok?.container_background_image_position || 'object-center'
                ]"
            />
        </template>
    </section>
</template>